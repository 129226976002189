import {
  Currency,
  CurrencyAmount,
  Fraction,
  Percent,
  Token,
} from "@aryze/sdk-core";
import { getAddress } from "@ethersproject/address";
import { BigNumber } from "@ethersproject/bignumber";
import { AddressZero } from "@ethersproject/constants";
import { Contract } from "@ethersproject/contracts";
import { JsonRpcSigner, Web3Provider } from "@ethersproject/providers";
import { SupportedChainId } from "constants/chains";
import {
  ArbitrumNetworkInfo,
  AvalancheNetworkInfo,
  BNBNetworkInfo,
  CeloNetworkInfo,
  // BaseNetworkInfo,
  MumbaiNetworkInfo,
  NetworkInfo,
  PolygonNetworkInfo,
} from "constants/networks";
import JSBI from "jsbi";
import { OptimismNetworkInfo } from "../constants/networks";
import { TokenAddressMap } from "../state/lists/hooks";

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

const ETHERSCAN_PREFIXES: { [chainId: number]: string } = {
  [SupportedChainId.MAINNET]: "",
  [SupportedChainId.ROPSTEN]: "ropsten.",
  [SupportedChainId.RINKEBY]: "rinkeby.",
  [SupportedChainId.GOERLI]: "goerli.",
  [SupportedChainId.KOVAN]: "kovan.",
  [SupportedChainId.OPTIMISM]: "optimistic.",
  [SupportedChainId.OPTIMISTIC_KOVAN]: "kovan-optimistic.",
};

export function getEtherscanLink(
  chainId: number,
  data: string,
  type: "transaction" | "token" | "address" | "block",
  networkVersion: NetworkInfo
): string {
  const prefix =
    networkVersion === BNBNetworkInfo
      ? "https://bscscan.com/"
      : networkVersion === PolygonNetworkInfo
      ? "https://polygonscan.com/"
      : networkVersion === MumbaiNetworkInfo
      ? "https://mumbai.polygonscan.com"
      : networkVersion === CeloNetworkInfo
      ? "https://celoscan.io/"
      : networkVersion === AvalancheNetworkInfo
      ? "https://avascan.info/"
      : networkVersion === ArbitrumNetworkInfo
      ? "https://arbiscan.io/"
      : // : networkVersion === BaseNetworkInfo
      // ? "https://basescan.org/"
      networkVersion === OptimismNetworkInfo
      ? "https://optimistic.etherscan.io"
      : `https://${
          ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[1]
        }etherscan.io`;

  if (networkVersion === OptimismNetworkInfo) {
    switch (type) {
      case "transaction": {
        return `${prefix}/tx/${data}`;
      }
      case "token": {
        return `${prefix}/address/${data}`;
      }
      case "block": {
        return `https://optimistic.etherscan.io`;
      }
      case "address":
      default: {
        return `${prefix}/address/${data}`;
      }
    }
  }

  if (networkVersion === ArbitrumNetworkInfo) {
    switch (type) {
      case "transaction": {
        return `${prefix}/tx/${data}`;
      }
      case "token": {
        return `${prefix}/address/${data}`;
      }
      case "block": {
        return "https://arbiscan.io/";
      }
      case "address":
      default: {
        return `${prefix}/address/${data}`;
      }
    }
  }

  switch (type) {
    case "transaction": {
      return `${prefix}/tx/${data}`;
    }
    case "token": {
      return `${prefix}/token/${data}`;
    }
    case "block": {
      return `${prefix}/block/${data}`;
    }
    case "address":
    default: {
      return `${prefix}/address/${data}`;
    }
  }
}

export const currentTimestamp = () => new Date().getTime();

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value
    .mul(BigNumber.from(10000).add(BigNumber.from(1000)))
    .div(BigNumber.from(10000));
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000));
}

const ONE = new Fraction(1, 1);
export function calculateSlippageAmount(
  value: CurrencyAmount<Currency>,
  slippage: Percent
): [JSBI, JSBI] {
  if (slippage.lessThan(0) || slippage.greaterThan(ONE))
    throw new Error("Unexpected slippage");
  return [
    value.multiply(ONE.subtract(slippage)).quotient,
    value.multiply(ONE.add(slippage)).quotient,
  ];
}
// account is not optional
export function getSigner(
  library: Web3Provider,
  account: string
): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(
  library: Web3Provider,
  account?: string
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

// account is optional
export function getContract(
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(
    address,
    ABI,
    getProviderOrSigner(library, account) as any
  );
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function isTokenOnList(
  tokenAddressMap: TokenAddressMap,
  token?: Token
): boolean {
  return Boolean(
    token?.isToken && tokenAddressMap[token.chainId]?.[token.address]
  );
}

export function feeTierPercent(fee: number): string {
  return (fee / 10000).toPrecision(1) + "%";
}

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}
