import { Token } from "@aryze/sdk-core";
import { SupportedChainId } from "constants/chains";
import Vibrant from "node-vibrant";
import { shade } from "polished";
import { useLayoutEffect, useMemo, useState } from "react";
import { isAddress } from "utils";
import uriToHttp from "utils/uriToHttp";
import { hex } from "wcag-contrast";

async function getColorFromToken(token: Token): Promise<string | null> {
  if (
    token.chainId === SupportedChainId.RINKEBY &&
    token.address === "0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735"
  ) {
    return Promise.resolve("#FAAB14");
  }

  const path = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${token.address}/logo.png`;

  return Vibrant.from(path)
    .getPalette()
    .then((palette) => {
      if (palette?.Vibrant) {
        let detectedHex = palette.Vibrant.hex;
        let AAscore = hex(detectedHex, "#FFF");
        while (AAscore < 3) {
          detectedHex = shade(0.005, detectedHex);
          AAscore = hex(detectedHex, "#FFF");
        }
        return detectedHex;
      }
      return null;
    })
    .catch(() => null);
}

async function getColorFromUriPath(uri: string): Promise<string | null> {
  const formattedPath = uriToHttp(uri)[0];

  return Vibrant.from(formattedPath)
    .getPalette()
    .then((palette) => {
      if (palette?.Vibrant) {
        return palette.Vibrant.hex;
      }
      return null;
    })
    .catch(() => null);
}

export function useColor(address?: string) {
  const [color, setColor] = useState("#2172E5");

  const formattedAddress = isAddress(address);

  const token = useMemo(() => {
    return formattedAddress ? new Token(1, formattedAddress, 0) : undefined;
  }, [formattedAddress]);

  useLayoutEffect(() => {
    let stale = false;

    if (token) {
      getColorFromToken(token).then((tokenColor) => {
        if (!stale && tokenColor !== null) {
          setColor(tokenColor);
        }
      });
    }

    return () => {
      stale = true;
      setColor("#2172E5");
    };
  }, [token]);

  return color;
}

export function useListColor(listImageUri?: string) {
  const [color, setColor] = useState("#2172E5");

  useLayoutEffect(() => {
    let stale = false;

    if (listImageUri) {
      getColorFromUriPath(listImageUri).then((color) => {
        if (!stale && color !== null) {
          setColor(color);
        }
      });
    }

    return () => {
      stale = true;
      setColor("#2172E5");
    };
  }, [listImageUri]);

  return color;
}
