import { AutoRow, RowBetween, RowFixed } from "components/Row";
import { useEthPrices } from "hooks/useEthPrices";
import React from "react";
import styled from "styled-components";
import { ExternalLink, TYPE } from "theme";
import { formatDollarAmount } from "utils/numbers";
import { SupportedNetwork } from "../../constants/networks";
import { useActiveNetworkVersion } from "../../state/application/hooks";
import Polling from "./Polling";
import { usePoolDatas } from "data/pools/poolData";
import { ONE_HOUR_SECONDS, TimeWindow } from "constants/intervals";
import { useTokenPriceData } from "state/tokens/hooks";
import { WETH9 } from "@aryze/sdk-core";

const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.black};
  padding: 10px 20px;
`;

const Item = styled(TYPE.main)`
  font-size: 12px;
`;

const StyledLink = styled(ExternalLink)`
  font-size: 12px;
  color: ${({ theme }) => theme.text1};
`;

const TopBar = () => {
  const [activeNetwork] = useActiveNetworkVersion();
  const ethPrices = useEthPrices();
  // const poolData=usePoolDatas(['0x2e19a098bbe871fd6a835238b48143201ffcb19c'])
  // const priceData = useTokenPriceData(WETH9[activeNetwork.id].address, ONE_HOUR_SECONDS, TimeWindow.DAY);

  // console.log(priceData);
  return (
    <Wrapper>
      <RowBetween>
        <Polling />
        <AutoRow gap="6px">
          {/* <RowFixed>
            {activeNetwork.id === SupportedNetwork.POLYGON ||
            activeNetwork.id === SupportedNetwork.POLYGON_MUMBAI ? (
              <Item>Matic Price:</Item>
            // ) : activeNetwork.id === SupportedNetwork.CELO ? (
            //   <Item>Celo Price:</Item>
            ) : activeNetwork.id === SupportedNetwork.BNB ? (
              <Item>BNB Price:</Item>
            // ) : activeNetwork.id === SupportedNetwork.AVALANCHE ? (
            //   <Item>AVAX Price:</Item>
            ) : (
              <Item>Eth Price:</Item>
            )}
            <Item fontWeight="700" ml="4px">
              {formatDollarAmount(priceData?.data.priceUSD)}
            </Item>
          </RowFixed> */}
        </AutoRow>
        <AutoRow gap="6px" style={{ justifyContent: "flex-end" }}>
          <StyledLink href="https://info-v2.mama.io/">V2 Analytics</StyledLink>
          <StyledLink href="https://docs.aryze.io/en/">Docs</StyledLink>
          <StyledLink href="https://app.mama.io/#/swap">App</StyledLink>
        </AutoRow>
      </RowBetween>
    </Wrapper>
  );
};

export default TopBar;
