import { EthereumNetworkInfo, NetworkInfo } from "constants/networks";

export function networkPrefix(activeNewtork: NetworkInfo) {
  const isEthereum = activeNewtork === EthereumNetworkInfo;

  if (isEthereum) {
    return "/";
  }
  const prefix = "/" + activeNewtork.route.toLocaleLowerCase() + "/";
  return prefix;
}
